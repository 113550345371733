import axios from 'axios';

export default function (options) {
    const apiClient = axios.create();
    apiClient.defaults.headers.common['Accept'] = 'application/json';
    apiClient.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    apiClient.defaults.withCredentials = false;
    apiClient.defaults.timeout = 15000;

    return apiClient;
}
