
import Toast, { POSITION, TYPE, useToast } from "vue-toastification";
import "../../scss/flash/index.scss";

const toast = useToast();

class Flash {
    install(vueApp, options) {
        vueApp.use(Toast, {
            // position: POSITION.TOP_RIGHT,
            position: POSITION.BOTTOM_CENTER,
            timeout: 10000,
            filterBeforeCreate: function (comingToast, activeToasts) {

                if (activeToasts.length > 0) {
                    if (activeToasts.filter(({ content, type }) => {
                        return content === comingToast.content && type === comingToast.type;
                    }).length) {
                        return false;
                    }
                }

                return comingToast;
            },
        });
    }
};

const notify = function (message, level) {
    if (message && level) {
        return toast(message);
    }

    return {
        open: ({ level, message }) => toast(message, { type: level }),
        success: (message) => toast.success(message),
        error: (message) => toast.error(message),
        info: (message) => toast.info(message),
        warning: (message) => toast.warning(message),
        toast
    };
};

export { notify, Flash };
