
import AllLocales from '../../js/Build/Locales';
import { round, compute } from '../../js/Helpers/Helpers';
import { Country, Language, Currency, Timezone, PhoneFormat, PersonalDocumentType, PersonalDocumentWithPhotoType, ZipcodeFormat, CountrySubdivision } from '../../js/Enums';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br.js';
// import 'dayjs/locale/en.js'
import utcPlugin from 'dayjs/plugin/utc';
import toArrayPlugin from 'dayjs/plugin/toArray';
import timezonePlugin from 'dayjs/plugin/timezone';
import localeDataPlugin from 'dayjs/plugin/localeData';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import durationPlugin from 'dayjs/plugin/duration';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import isoWeekPlugin from 'dayjs/plugin/isoWeek';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';

import { createI18n, useI18n } from 'vue-i18n';
// import enUsVuetifyMessages from 'vuetify/lib/locale/en.mjs';
import ptBrVuetifyMessages from '../../js/Build/vuetify-pt-br';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';

const availableLocales = AllLocales.map(({ mainLanguage }) => mainLanguage.substring(0, 2));

const vuetifyMessages = {
    pt: ptBrVuetifyMessages,
    // us: enUsVuetifyMessages
};

const messages = {};

availableLocales.forEach((locale, x) => {
    messages[locale] = AllLocales[x].translations;
    messages[locale]['$vuetify'] = vuetifyMessages[locale];
});

export default class Locale {
    dayjs = undefined;

    constructor({ pageProps }) {

        this.mainLanguage = pageProps?._currentLocale;
        this.fallbackLanguage = pageProps?._fallbackLocale;

        this.specs = Locale.#compileLocalizedEnums(this.mainLanguage);

        dayjs.extend(utcPlugin);
        dayjs.extend(timezonePlugin);
        dayjs.extend(localeDataPlugin);
        dayjs.extend(toArrayPlugin);
        dayjs.extend(customParseFormatPlugin);
        dayjs.extend(durationPlugin);
        dayjs.extend(isBetweenPlugin);
        dayjs.extend(isoWeekPlugin);
        dayjs.extend(relativeTimePlugin);

        dayjs.locale({ 'pt': 'pt-br', 'en': 'en' }[this.mainLanguage]);
        dayjs.tz.setDefault(this.specs.mainTimezone);

        this.dayjs = dayjs;

        this.translator = createI18n({
            availableLocales,
            locale: this.mainLanguage,
            fallbackLocale: this.fallbackLanguage,
            messages,
            legacy: false,
            missingWarn: false,
        });

        this.jsLocale = new Intl.Locale(this.mainLanguage);

        this.vuetifyLocale = {
            adapter: createVueI18nAdapter({ i18n: this.translator, useI18n }),
            locale: this.mainLanguage
        }

        this.vuetifyDate = {
            // adapter: new DayjsAdapter({ locale: this.mainLanguage, instance: this.dayjs }),
            // locale: 'en-US'
        };
    }

    get() {
        return this.translator.global.t(...arguments);
    }

    has() {
        return this.translator.global.te(...arguments);
    }

    formatDate(value, style = 'short', tz) {
        if (!value) {
            return null;
        }

        return new Intl.DateTimeFormat(this.jsLocale.baseName, {
            dateStyle: style,
            timeZone: tz || this.specs.mainTimezone.value,
        }).format(dayjs(value).toDate());
    }

    formatDatetime(value, style = 'short', tz) {
        if (!value) {
            return null;
        }

        return new Intl.DateTimeFormat(this.jsLocale.baseName, {
            dateStyle: style,
            timeStyle: style,
            timeZone: tz || this.specs.mainTimezone.value,
        }).format(dayjs(value).toDate());
    }

    formatTime(value, style = 'short', tz) {
        if (!value) {
            return null;
        }

        return new Intl.DateTimeFormat(this.jsLocale.baseName, {
            timeStyle: style,
            timeZone: tz || this.specs.mainTimezone.value,
        }).format(dayjs(value).toDate());
    }

    formatNumber(value, style = 'decimal') {
        if (typeof value !== 'number') {
            return value;
        }

        return new Intl.NumberFormat(this.jsLocale.baseName, { style }).format(value);
    }

    numberFormat(options) {
        return new Intl.NumberFormat(this.jsLocale.baseName, options || { style: 'decimal' });
    }

    formatCurrency(value, onEmpty) {
        if (value === null) {
            return null;
        }

        if (typeof value === 'number') {
            value = {
                amount: value,
                currency: this.specs.mainCurrency.value,
            };
        }
        else if (typeof value === 'object' && value.toObject !== undefined) {
            value = value.toObject();
        }

        let { amount, currency } = value;
        amount = parseInt(amount);

        if (amount === 0 && onEmpty !== undefined) {
            return compute(onEmpty);
        }

        return new Intl.NumberFormat(this.jsLocale.baseName, {
            style: 'currency',
            currency,
        }).format(round(amount / 100, 2));
    }

    install(vueApp) {
        vueApp.use(this.translator);
    }








    static #compileLocalizedEnums(language) {
        let temp = AllLocales.find(({ mainLanguage: xMainLanguage }) => String(xMainLanguage).startsWith(language));

        return {
            country: Country.tryFrom(temp.country),
            mainLanguage: Language.tryFrom(temp.mainLanguage),
            mainCurrency: Currency.tryFrom(temp.mainCurrency),
            numeric: {
                thousand: temp.thousandSeparator,
                decimal: temp.decimalSeparator
            },
            timezones: Timezone.tryFrom(temp.timezones),
            mainTimezone: Timezone.tryFrom(temp.mainTimezone),
            documentTypes: PersonalDocumentType.tryFrom(temp.personalDocumentTypes),
            documentWithPhotoTypes: PersonalDocumentWithPhotoType.tryFrom(temp.personalDocumentWithPhotoTypes),
            phoneFormat: PhoneFormat.tryFrom(temp.phoneFormat),
            zipcodeFormat: ZipcodeFormat.tryFrom(temp.zipcodeFormat),
            dateFormat: temp.dateFormat,
            datetimeFormat: temp.datetimeFormat,
            timeFormat: temp.timeFormat,
            ufs: CountrySubdivision.tryFrom(temp.ufs),
        };
    }
}
