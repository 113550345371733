<script setup>
    import { shallowRef, ref, inject } from 'vue';
    import { debounce } from 'lodash';
    import { useMemoize } from '@vueuse/core';
    import Env from '@/Helpers/Env';

    defineOptions({
        inheritAttrs: false
    });

    const api = inject('api');
    const route = inject('route');
    const notify = inject('notify');

    const searching = shallowRef(false);

    const inputModel = shallowRef('');

    const results = ref([]);

    const firstSearch = shallowRef(true);


    // 

    const searcher = useMemoize(
        async (searchPattern, onError) => {
            searching.value = true;

            let result = [];

            try {
                const { data } = await api.get(route('app-site.events.search'), {
                    params: {
                        query: searchPattern
                    }
                });

                result = data;
            }
            catch (error) {
                if (onError) {
                    onError(error);
                }
            }

            searching.value = false;

            return result;
        }, {
        getKey: (searchPattern) => searchPattern
    });



    const onChange = debounce(async function (newInputValue, validate) {

        const { valid } = await validate();

        if (!valid) {
            return;
        }

        newInputValue = typeof newInputValue === 'string' ? newInputValue.trim() : false;
        newInputValue = newInputValue.length > 3 ? newInputValue : false;

        if (!newInputValue) {
            return;
        }

        results.value = await searcher(newInputValue, (error) => {
            if (Env.DEBUG) {
                console.error(error);
            }
            notify().error('Ocorreu um erro ao pesquisar eventos');
        });

        if (firstSearch.value === true) {
            firstSearch.value = false;
        }

    }, 500);

</script>

<template>

    <slot name="default">
        <v-icon icon="mdi-magnify"></v-icon>
    </slot>

    <v-dialog activator="parent" scrim="black" transition="dialog-transition" :opacity="0.85" width="1000"
        class="text-white align-start mt-5 mt-lg-15" :="$attrs" #="{ isActive }">

        <v-btn icon="mdi-close" variant="plain" @click="() => { isActive.value = false; inputModel = '' }"
            class="position-absolute" style="right: 0;"></v-btn>

        <h4 class="text-h4 px-3 pb-5 pb-lg-10 text-center w-100">O que você quer encontrar?</h4>

        <v-form @submit.prevent="() => false" novalidate #="{ validate }">
            <v-text-field v-model="inputModel" variant="solo" color="white" rounded="pill" class="mb-5"
                @update:model-value="(newValue) => onChange(newValue, validate)" clearable validate-on="submit"
                placeholder="Busque por eventos"
                :rules="[(newValue) => String(newValue).length > 3 || 'Use 3 caracteres ou mais']" autofocus>


                <template #message="{ message }">
                    <span class="text-white">
                        {{ message }}
                    </span>
                </template>

                <template #prepend-inner>
                    <v-icon icon="mdi-magnify" size="large"></v-icon>
                </template>

                <template #append-inner>
                    <v-progress-circular v-if="searching" indeterminate color="primary"></v-progress-circular>
                </template>
            </v-text-field>

        </v-form>
        <v-sheet v-if="results?.length > 0" rounded="lg" class="py-3">
            <v-list lines="two" variant="flat">
                <template v-for="xEvent in results" :key="xEvent.uuid">
                    <v-hover #="{ isHovering, props }">
                        <v-list-item :to="$route('app-site.events.show', [xEvent?.slug || xEvent.uuid])"
                            :prepend-avatar="xEvent?.image?.small_land || $imgError" class="py-5"
                            :base-color="isHovering ? 'primary' : undefined" :="props">

                            <template #title>
                                <v-list-item-title>{{ xEvent.title }}</v-list-item-title>
                            </template>
                        </v-list-item>
                    </v-hover>
                </template>
            </v-list>
        </v-sheet>

        <v-sheet v-else-if="results?.length === 0 && !firstSearch && !searching" color="transparent" elevation="0">
            <div class="text-center">sem resultados</div>
        </v-sheet>

    </v-dialog>



</template>


<style scoped lang="scss"></style>