// import route from 'ziggy-js';
import { route as ziggyRoute } from '../../../vendor/tightenco/ziggy/src/js/index.js';
import Env from './Env.js';
import { Ziggy } from '../Build/Routes.js';
import { captureException } from '@sentry/browser';

const tapZiggy = (tapCallback) => tapCallback(Ziggy);

const routeExist = (routeName) => Ziggy.routes?.[routeName] !== undefined;

export { tapZiggy, routeExist };

export default function (name, params, absolute) {

    if (name === 'router') {
        return ziggyRoute(undefined, undefined, undefined, Ziggy);
    }

    const routeExist = Ziggy.routes[name] !== undefined;

    if (!routeExist && Env.DEBUG) {
        console.warn(
            `RoutesProvider: UNKNOWN ROUTE "${name}". Available route names: `,
            Object.keys(Ziggy.routes)
        );
    }

    try {
        return ziggyRoute(name, params, absolute, Ziggy);
    }
    catch (error) {
        captureException(error);
        return '/#';
    }
};
