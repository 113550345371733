
import Dinero from 'dinero.js';
import Env from './Env';
import { throttle } from 'lodash';

const mapEventListeners = function (element, map) {
    const events = Object.keys(map);

    if (events.length === 0) {
        return;
    }

    events.forEach((eventName) => {
        element.addEventListener(eventName, map[eventName]);
    });

    map._element = element;
    map._events = events;
    map._removeAll = function () {
        this._events = this._events
            .map((eventName) => {
                this._element.removeEventListener(
                    eventName,
                    this[eventName]
                );
                return null;
            })
            .filter(Boolean);
    };

    return map;
};

const isEmail = function (input) {

    return /^\S+@\S+\.\S+$/g.test(input);

    // return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(String(input));
};

const onlyNumbers = function (input) {
    return String(input).replace(/[^0-9]/g, '');
};

const compute = function (computeCallback, ...args) {
    return typeof computeCallback === 'function'
        ? computeCallback(...args)
        : computeCallback;
}

const consolePrettyJson = function (data, space = 4) {
    console.log(JSON.stringify(data, null, space));
};

const round = function (number, precision = 0) {
    const factor = Math.pow(10, precision);
    const tempNumber = number * factor;
    const roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
};

const firstUpper = function (input) {
    input = String(input);
    return input.charAt(0).toUpperCase() + input.slice(1);
};

const isValidUrl = function (url, base) {
    try {
        return Boolean(new URL(url, base));

    } catch (error) {
        return false;
    }
};

const mapToObject = function (inputArray, callback) {
    let result = {};

    inputArray.forEach(callback, result);

    return result;
};

const setProperties = function (target, properties = {}) {
    Object.keys(properties).forEach(function (propertyName) {
        target[propertyName] = properties?.[propertyName];
    });

    return target;
};

const currency = function ({ amount, currency }) {
    return new Dinero({
        amount: parseInt(amount),
        currency
    });
};

const arrayRand = function (array) {
    return array[Math.floor(Math.random() * array.length)];
};

const arrayRange = function (number) {
    return [...Array(number).keys()];
};

const getVuetifyColor = function (basic, levels = [1, 2, 3, 4]) {
    const color = arrayRand([
        'red',
        'pink',
        'purple',
        'deep-purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'grey'
    ]);

    if (basic) {
        return color;
    }

    const variants = ['darken', 'lighten'].concat(['brow', 'blue-grey', 'grey'].includes(color) ? [] : ['accent']);

    return `${color}-${arrayRand(variants)}-${arrayRand(levels)}`;
};

const find = function (inputArray, conditions) {
    return inputArray.find((x) => {

        let result = false;

        Object.keys(conditions).forEach((key) => {
            result = x[key] === conditions[key];
        });

        return result;
    });
};

const getMapsEmbed = function (locationStr) {
    if (!locationStr) {
        return;
    }

    locationStr = encodeURI(locationStr);

    return `https://google.com/maps/embed/v1/place?key=${Env.GOOGLE_MAPS_API_KEY}&q=${locationStr}`;
}

const hashCyrb53 = function (str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    // number
    // return 4294967296 * (2097151 & h2) + (h1 >>> 0);

    // array
    // return [h2 >>> 0, h1 >>> 0];

    // hex
    return (h2 >>> 0).toString(16).padStart(8, 0) + (h1 >>> 0).toString(16).padStart(8, 0);

    // bitint
    // return 4294967296n * BigInt(h2) + BigInt(h1);
};

// const sleep = function (milliseconds) {
//     if (!milliseconds) {
//         return;
//     }

//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(true);
//         }, milliseconds);
//     });
// };

// const sleep2 = function (milliseconds, precision = 500) {

//     console.log('sleeping');

//     const target = Date.now() + milliseconds;

//     const x = throttle(() => Date.now() > target, precision);

//     while (true) {
//         if (x()) {
//             break;
//         }
//     }

//     console.log('sleeped');

//     return;
// };

const mapWithKeys = function (arrayOrObject, callback) {
    return Object.entries(arrayOrObject).reduce(function (accumulator, [key, value]) {
        const { key: newKey, value: newValue } = callback(value, key);

        accumulator[newKey] = newValue;

        return accumulator;
    }, {});
};

export {
    mapEventListeners,
    isEmail,
    onlyNumbers,
    round,
    compute,
    consolePrettyJson,
    firstUpper,
    isValidUrl,
    mapToObject,
    setProperties,
    currency,
    arrayRand,
    arrayRange,
    getVuetifyColor,
    find,
    getMapsEmbed,
    hashCyrb53,
    // sleep,
    // sleep2,
    mapWithKeys,
}
