<script setup>
    import { usePage } from '@inertiajs/vue3';
    import { computed } from 'vue';

    const props = defineProps({
        user_key: {
            type: String,
            default: '_user'
        }
    });

    const user = computed(() => usePage().props[props.user_key]);

</script>

<template>
    <v-list color="primary" min-width="250" contained class="pb-0">

        <v-list-item :prepend-avatar="user?.avatar?.small_square || '/images/profile150.png'" :title="user?.name">
            <template #subtitle>
                <v-list-item-subtitle class="pb-3">
                    {{ user?.email }}
                </v-list-item-subtitle>
            </template>
        </v-list-item>

        <v-divider class=""></v-divider>

        <v-list-item prepend-icon="mdi-swap-horizontal-circle" :href="$route('producer.home')">
            Área do produtor
        </v-list-item>

        <v-divider class=""></v-divider>

        <v-list-item prepend-icon="mdi-account" :to="$route('app-site.user.edit')">
            Minha conta
        </v-list-item>

        <v-list-item prepend-icon="mdi-ticket-confirmation" :to="$route('app-site.user.menu.tickets.index')">
            Meus ingressos
        </v-list-item>

        <v-list-item prepend-icon="mdi-cart" :to="$route('app-site.user.menu.orders.index')">
            Meus pedidos
        </v-list-item>

        <v-list-item prepend-icon="mdi-heart" base-color="pink-accent-4" :to="$route('app-site.user.lists.favorites')">
            Meus favoritos
        </v-list-item>

        <v-divider class=""></v-divider>

        <v-list-item prepend-icon="mdi-logout" :to="{ action: $route('app-site.user.logout'), method: 'post' }">
            Desconectar
        </v-list-item>
    </v-list>

</template>