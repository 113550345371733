<script setup>

    import { usePage } from '@inertiajs/vue3';
    import { computed } from 'vue';

    const title = computed(() => usePage().props?.title);
    const message = computed(() => usePage().props?.message);
    const homeUrl = computed(() => usePage().props?.homeUrl);
    const _global = computed(() => usePage().props?._global);

</script>


<template>
    <v-empty-state>
        <template #media>
            <v-img src="/images/brand/logo-black-1024.png" height="75" class="mb-10"></v-img>
        </template>

        <template #headline>
            <div class="text-uppercase mt-10 text-h4">
                {{ title || 'Oops! Alguma coisa deu errado' }}
            </div>
        </template>

        <template v-if="message" #title>
            <div class="my-5 font-weight-regular">
                {{ message }}
            </div>
        </template>

        <v-spacer></v-spacer>

        <template #actions>
            <v-btn v-if="$window.history.length > 2" @click="() => $window.history.back()" text="voltar" variant="tonal"
                prepend-icon="mdi-arrow-left" color="primary"></v-btn>

            <v-btn text="Início" color="primary" prepend-icon="mdi-home" :href="homeUrl || '/'"></v-btn>

            <v-btn v-if="_global.email" text="Ajuda" color="primary" prepend-icon="mdi-email"
                :href="`mailto:${_global.email}`"></v-btn>

        </template>

    </v-empty-state>
</template>
