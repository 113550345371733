<script setup>

    import { computed, inject, shallowRef } from 'vue';
    import TicketsList from './TicketsList.vue';
    import { getMapsEmbed } from '@/Helpers/Helpers';

    const props = defineProps({
        pageTitle: String,

        event: Object,
        owner: Boolean,
        _global: Object,
        hasSchedule: Boolean,
        ticketType: Object,
    });

    const locale = inject('locale');

    const calendarDates = computed(() => {
        return props.event.dates?.map(({ startAt, endAt }) => locale.dayjs(startAt).toDate()) || [];
    });

    const locationGoogleMapUrl = computed(() => {
        if (!props.event?.locationDescription) {
            return;
        }

        return getMapsEmbed(props.event.locationDescription);
    });

    const locationDialogModel = shallowRef(false);

    const toggleLocationDialog = function () {
        locationDialogModel.value = !locationDialogModel.value;
    };

    const dateDialogModel = shallowRef(false);

    const toggleDateDialog = function () {
        dateDialogModel.value = !dateDialogModel.value;
    };

</script>

<template>
    <v-app>
        <x-support></x-support>

        <x-scroll-to-top color="primary" :size="$vuetify.display.mobile ? 'small' : 'large'" variant="elevated"
            :right="$vuetify.display.mobile ? 10 : 30">
        </x-scroll-to-top>

        <v-toolbar flat color="primary">
            <v-toolbar-title class="text-center">
                {{ pageTitle || event.title }}
            </v-toolbar-title>

            <template #prepend>
                <v-btn v-if="$window.history.length > 1" icon="mdi-arrow-left" v-tooltip:end="'Voltar'"
                    @click="() => $window.history.back()"></v-btn>
                <v-btn v-else icon="mdi-home" v-tooltip:end="'Início'" :to="$route('app-site.home')"></v-btn>
            </template>

            <template #append>
                <template v-if="owner">
                    <v-btn v-if="$vuetify.display.mobile" icon="mdi-cog"
                        :href="$route('producer.menu.events.show', [event.uuid])"></v-btn>
                    <v-btn v-else prepend-icon="mdi-cog" text="Gerenciar evento"
                        :href="$route('producer.menu.events.show', [event.uuid])"></v-btn>
                </template>


                <h6 v-else-if="!$vuetify.display.mobile" :href="$route('app-site.home')" class="pe-3">
                    <v-img src="/images/brand/logo-white-512.png" width="150"></v-img>
                </h6>
            </template>
        </v-toolbar>



        <v-main>

            <v-dialog v-model="locationDialogModel" :fullscreen="$vuetify.display.mobile" eager
                :max-width="$vuetify.display.mobile ? undefined : 800">
                <template #default="{ isActive }">
                    <v-card flat border class="pa-lg-5" title="Localização do evento">
                        <template #append>
                            <v-btn icon="mdi-close" @click="() => isActive.value = false"></v-btn>
                        </template>

                        <v-card-text>
                            <iframe :src="locationGoogleMapUrl" style="width: 100%;min-height: 500px;"
                                frameborder="0"></iframe>
                        </v-card-text>
                    </v-card>
                </template>
            </v-dialog>

            <v-dialog v-if="calendarDates.length" v-model="dateDialogModel" :fullscreen="$vuetify.display.mobile"
                :max-width="$vuetify.display.mobile ? undefined : 700">
                <template #default="{ isActive }">
                    <v-card class="pa-5" title="Datas do evento">
                        <template #append>
                            <v-btn icon="mdi-close" @click="() => isActive.value = false"></v-btn>
                        </template>

                        <v-date-picker readonly landscape class="mx-auto w-100"
                            :model-value="calendarDates"></v-date-picker>
                    </v-card>
                </template>
            </v-dialog>

            <template v-if="event?.image?.large_land || event?.image?.original">

                <v-img v-if="$vuetify.display.mobile" :src="event?.image?.large_land || event?.image?.original"
                    :aspect-ratio="16 / 9"></v-img>

                <v-container v-else fluid class="event-image-container pa-0 mb-10"
                    :style="{ background: `rgba(255,255,255,0.1) url(${event?.image?.large_land || event?.image?.original})`, backgroundSize: 'cover', backgroundBlendMode: 'lighten' }">
                    <v-row justify="center" style="backdrop-filter: blur(20px) ;" no-gutters dense>
                        <v-col cols="12" lg="10" xl="8">
                            <v-img :src="event?.image?.large_land || event?.image?.original" :aspect-ratio="16 / 9"
                                class="event-image rounded-xl elevation-10 mt-5" style="transform: scale(1.05);">
                            </v-img>
                        </v-col>
                    </v-row>
                </v-container>

            </template>


            <v-container class="mt-lg-5">
                <v-row justify="center">
                    <v-col cols="12" xl="10">
                        <v-row>
                            <v-col cols="12" lg="8" xl="9">
                                <v-card variant="text" class="pa-5">
                                    <template #append>
                                        <v-btn v-if="hasSchedule" text="Programação" color="primary" class="mx-1"
                                            prepend-icon="mdi-timeline-clock" size="small"
                                            :to="$route('app-site.events.schedule', [event.uuid])"></v-btn>

                                        <v-btn v-if="event?.locationDescription" text="Ver local" color="primary"
                                            class="mx-1" prepend-icon="mdi-map" size="small"
                                            @click="toggleLocationDialog"></v-btn>

                                        <v-btn v-if="calendarDates.length" text="Ver datas" color="primary" class="mx-1"
                                            prepend-icon="mdi-calendar-range" size="small"
                                            @click="toggleDateDialog"></v-btn>

                                    </template>

                                    <h1 class="text-h2 mb-10 mt-10">
                                        {{ pageTitle || event.title }}
                                    </h1>

                                    <v-card-text class="mb-10">
                                        <p v-if="event?.dateDescription">
                                            <v-icon icon="mdi-calendar"></v-icon>
                                            <strong>Datas: </strong>

                                            <a @click="toggleDateDialog" :text="event.dateDescription" role="button"
                                                class="text-primary text-decoration-underline"
                                                v-tooltip:end="'Ver no calendário'"></a>
                                        </p>

                                        <v-spacer class="py-3"></v-spacer>

                                        <p v-if="event?.locationDescription">
                                            <v-icon icon="mdi-map-marker"></v-icon>
                                            <strong>Local: </strong>
                                            <a @click="toggleLocationDialog"
                                                :text="event.locationDescription.toUpperCase()" role="button"
                                                class="text-primary text-decoration-underline"
                                                v-tooltip:end="'Ver no mapa'"></a>
                                        </p>
                                    </v-card-text>

                                    <v-card-text v-if="ticketType?.description" v-html="ticketType.description"
                                        class="my-10 pb-10">
                                    </v-card-text>

                                    <v-card-text v-else-if="!ticketType && event?.description" v-html="event.description"
                                        class="my-10">
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <v-col v-if="!$vuetify.display.mobile" cols="12" lg="4" xl="3">
                                <TicketsList :selected="ticketType?.uuid" border class="px-3 pt-3 pa-0" rounded="xl"
                                    :event="event">
                                </TicketsList>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>



            </v-container>
        </v-main>

        <v-bottom-sheet v-if="$vuetify.display.mobile">
            <template #activator="{ props }">
                <v-footer v-if="$vuetify.display.mobile" app>
                    <v-btn color="primary" text="Ver entradas" block prepend-icon="mdi-chevron-up" :="props"></v-btn>
                </v-footer>
            </template>

            <template #default="{ isActive }">
                <TicketsList :selected="ticketType?.uuid" flat :rounded="0" :event="event"
                    @cancel="() => isActive.value = false"></TicketsList>
            </template>
        </v-bottom-sheet>

        <vibeland-footer></vibeland-footer>
    </v-app>
</template>

<style lang="scss"></style>
