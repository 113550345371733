<script setup>

    import { inject } from 'vue';
    import EventScheduleList from '../../../../../../resources/vue/EventScheduleList/theme4.vue';
    import { router } from '@inertiajs/vue3';
    import { toValue } from '@vueuse/core';
    import { uniq } from 'lodash';

    const props = defineProps({
        _user: Object,
        _global: Object,

        event: Object,
        owner: Boolean,

        favoriteListTag: String,
        favorites: Array,
        pageTitle: String,
        isCustomDomain: Boolean,
        tickets: Object,
        itemWithFocus: String,
    });

    const route = inject('route');
    const notify = inject('notify');

    // 

    const allVenues = uniq((props.event.schedule?.publishedItems || []).map(({ venue: xVenue }) => xVenue).filter(Boolean));

    const allTypes = uniq((props.event.schedule?.publishedItems || []).map(({ type: xType }) => xType).filter(Boolean));

    const allHosts = uniq((props.event.schedule?.publishedItems || []).reduce((accumulator, { hosts: xHosts }) => accumulator.concat(xHosts), []).filter(Boolean));

    const allTags = uniq((props.event.schedule?.publishedItems || []).reduce((accumulator, { tags: xTags }) => accumulator.concat(xTags), []).filter(Boolean));

    const onToggleLike = function (scheduleItemId) {

        router.post(route('app-site.user.lists.item.toggle', [props.favoriteListTag, 'event_schedule_item', scheduleItemId]), {}, {});
    }

</script>

<template>
    <v-app>
        <x-support></x-support>

        <x-scroll-to-top color="primary" :size="$vuetify.display.mobile ? 'small' : 'large'" variant="elevated"
            :right="$vuetify.display.mobile ? 10 : 30">
        </x-scroll-to-top>

        <v-toolbar flat color="primary">
            <v-toolbar-title class="text-center">
                {{ event.title }}
            </v-toolbar-title>

            <template #prepend>
                <v-btn icon="mdi-arrow-left" v-tooltip:end="'Ir para evento'"
                    :="{ [isCustomDomain ? 'href' : 'to']: $route('app-site.events.show', [event.uuid]) }"></v-btn>
            </template>

            <template #append>
                <template v-if="owner">
                    <v-btn v-if="$vuetify.display.mobile" icon="mdi-cog"
                        :href="$route('producer.menu.events.schedule', [event.uuid])"></v-btn>
                    <v-btn v-else prepend-icon="mdi-cog" text="Gerenciar agenda"
                        :href="$route('producer.menu.events.schedule', [event.uuid])"></v-btn>
                </template>


                <h6 v-else-if="!$vuetify.display.mobile" :href="$route('app-site.home')" class="pe-3">
                    <v-img src="/images/brand/logo-white-512.png" width="150"></v-img>
                </h6>
            </template>
        </v-toolbar>


        <v-main>

            <v-container class="mt-lg-5">

                <v-row justify="center" dense class="mb-5">
                    <v-col cols="12" xl="8">
                        <h1 class="text-h3 text-center">
                            Agenda -
                            <a :href="$route('app-site.events.show', [event.uuid])" target="_blank"
                                style="color:inherit;">
                                {{ event.title }}
                            </a>
                        </h1>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col cols="12" xl="9">
                        <EventScheduleList :event-id="event.uuid" :timezone="event.schedule.timezone"
                            :user-favorites="favorites" :items="event.schedule?.publishedItems || []"
                            :all-types="allTypes" :all-venues="allVenues" :all-hosts="allHosts" :all-tags="allTags"
                            :user-tickets="tickets" @toggle-like="onToggleLike" :item-with-focus="itemWithFocus">
                        </EventScheduleList>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <vibeland-footer></vibeland-footer>
    </v-app>
</template>