export default {
    badge: 'Distintivo',
    open: 'Abrir',
    close: 'Fechar',
    dismiss: 'Dispensar',
    confirmEdit: {
        ok: 'OK',
        cancel: 'Cancelar'
    },
    dataIterator: {
        noResultsText: 'Nenhum dado encontrado',
        loadingText: 'Carregando itens...'
    },
    dataTable: {
        itemsPerPageText: 'Linhas por página:',
        ariaLabel: {
            sortDescending: 'Ordenado decrescente.',
            sortAscending: 'Ordenado crescente.',
            sortNone: 'Não ordenado.',
            activateNone: 'Ative para remover a ordenação.',
            activateDescending: 'Ative para ordenar decrescente.',
            activateAscending: 'Ative para ordenar crescente.'
        },
        sortBy: 'Ordenar por'
    },
    dataFooter: {
        itemsPerPageText: 'Itens por página:',
        itemsPerPageAll: 'Todos',
        nextPage: 'Próxima página',
        prevPage: 'Página anterior',
        firstPage: 'Primeira página',
        lastPage: 'Última página',
        pageText: '{0}-{1} de {2}'
    },
    dateRangeInput: {
        divider: 'até'
    },
    datePicker: {
        itemsSelected: '{0} selecionados',
        range: {
            title: 'Selecione as datas',
            header: 'Digite as datas'
        },
        title: 'Selecione a data',
        header: 'Digite a data',
        input: {
            placeholder: 'Insira a data'
        }
    },
    noDataText: 'Não há dados disponíveis',
    carousel: {
        prev: 'Visão anterior',
        next: 'Próxima visão',
        ariaLabel: {
            delimiter: 'Slide {0} de {1} do carrossel'
        }
    },
    calendar: {
        moreEvents: 'Mais {0}',
        today: 'Hoje'
    },
    input: {
        clear: 'Limpar {0}',
        prependAction: '{0} prepended action',
        appendAction: '{0} appended action',
        otp: 'Por favor insira o caractere OTP {0}'
    },
    fileInput: {
        counter: '{0} arquivo(s)',
        counterSize: '{0} arquivo(s) ({1} no total)'
    },
    timePicker: {
        am: 'AM',
        pm: 'PM',
        title: 'Selecione hora e minuto'
    },
    pagination: {
        ariaLabel: {
            root: 'Navegação de paginação',
            next: 'Próxima página',
            previous: 'Página anterior',
            page: 'Ir à página {0}',
            currentPage: 'Página atual, página {0}',
            first: 'Primeira página',
            last: 'Última página'
        }
    },
    stepper: {
        next: 'Próximo',
        prev: 'Anterior'
    },
    rating: {
        ariaLabel: {
            item: 'Avaliação {0} de {1}'
        }
    },
    loading: 'Carregando...',
    infiniteScroll: {
        loadMore: 'Carregar mais',
        empty: 'Não há mais dados'
    }
};
