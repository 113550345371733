<script setup>

    import { onMounted, shallowRef } from 'vue';

    const props = defineProps({
        startAt: Number,
        _global: Object,
        homeUrl: String,
    });

    const time = shallowRef();

    onMounted(() => {
        time.value = Math.round((Date.now() - (props.startAt * 1000)));
    });

</script>

<template>

    <v-app>
        <x-support></x-support>


        <v-empty-state>
            <template #media>
                <v-img src="/images/brand/logo-black-1024.png" height="75" class="mb-10"></v-img>
            </template>

            <template #title>

                <v-icon icon="mdi-circle" color="success" size="x-small" class="me-3"></v-icon>

                Response successfully rendered
                <span>
                    {{ time }} ms
                </span>
            </template>

            <v-spacer></v-spacer>

            <template #actions>
                <v-btn v-if="$window.history.length > 2" @click="() => $window.history.back()" text="voltar"
                    variant="tonal" prepend-icon="mdi-arrow-left" color="primary"></v-btn>

                <v-btn text="Início" color="primary" prepend-icon="mdi-home" :href="homeUrl || '/'"></v-btn>

            </template>

        </v-empty-state>


    </v-app>



</template>

<style lang="scss" scoped></style>
